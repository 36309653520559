// 用户

const coummunity = () => import("../views/community/coummunity.vue"); // 社区入口
const myPage = () => import("../views/community/myPage/myPage.vue"); // 我的主页
const view = () => import("../views/community/myPage/view.vue"); // 视角详情页

export default [
	{
		path: "/coummunity",
		name: "coummunity",
		component: coummunity,
		redirect: { name: "myPage" },
		meta: {
			keepAlive: false,
		},
		children: [
			{
				path: "mypage",
				name: "myPage",
				component: myPage,
				meta: {
					title: "我的主页",
					keepAlive: false,
				},
			},
			{
				path: "mypage/view/:id",
				name: "view",
				component: view,
				meta: {
					keepAlive: false,
				},
			},
		],
	},
];
