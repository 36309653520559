var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "欢迎加入观天者",
        visible: _vm.visibleRegister,
        "append-to-body": true,
        "show-close": true,
        width: "450px",
        "destroy-on-close": true,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visibleRegister = $event
        },
        open: _vm.openDialog,
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "registerForm",
          attrs: { model: _vm.registerForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c("el-input", {
                attrs: { id: "email", placeholder: "请输入邮箱" },
                model: {
                  value: _vm.registerForm.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "email", $$v)
                  },
                  expression: "registerForm.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  id: "pwd",
                  "show-password": "",
                  placeholder: "密码8-16位，须包含大小写字母及数字",
                },
                model: {
                  value: _vm.registerForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "password", $$v)
                  },
                  expression: "registerForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "confirmPwd" } },
            [
              _c("el-input", {
                attrs: {
                  id: "confirmPwd",
                  "show-password": "",
                  placeholder: "确认密码",
                },
                model: {
                  value: _vm.registerForm.confirmPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "confirmPwd", $$v)
                  },
                  expression: "registerForm.confirmPwd",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "phone",
                  attrs: { id: "phone", placeholder: "请输入手机号" },
                  model: {
                    value: _vm.registerForm.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "phone", $$v)
                    },
                    expression: "registerForm.phone",
                  },
                },
                [
                  _c("template", { slot: "prefix" }, [
                    _c("span", { staticClass: "area-code" }, [_vm._v("+86")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "code" } },
            [
              _c(
                "el-input",
                {
                  attrs: { id: "code", placeholder: "请输入短信验证码" },
                  model: {
                    value: _vm.registerForm.code,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "code", $$v)
                    },
                    expression: "registerForm.code",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "suffix" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "send-code csp",
                          attrs: { id: "CaptchaId", disabled: _vm.sendCodeBtn },
                          on: { click: _vm.sendCode },
                        },
                        [_vm._v(" " + _vm._s(_vm.codeText) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "other",
              attrs: { prop: "agree", "show-message": false },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.registerForm.agree,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "agree", $$v)
                    },
                    expression: "registerForm.agree",
                  },
                },
                [_vm._v("我已阅读并同意 ")]
              ),
              _c(
                "span",
                {
                  staticClass: "to-agreement csp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toAgreement.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("《观天者用户服务协议》")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isRegisterLoading },
              on: { click: _vm.register },
            },
            [_vm._v(_vm._s(_vm.isRegisterLoading ? "注册中" : "注册") + " ")]
          ),
          _c("p", { staticClass: "to-login" }, [
            _vm._v("已有账号？去"),
            _c(
              "span",
              { staticClass: "login csp", on: { click: _vm.toLogin } },
              [_vm._v("登录")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }