// import { get, post, url} from '../service/ApiDecorator'
import { get, post, url } from "../http";

class Login {
	// 查询邮箱或手机号是否已存在
	@url("/auth/account-exists")
	@post
	getAccountExists() {}
	// 发送短信验证码
	@url("/auth/send-sms-code")
	@post
	sendSmsCode() {}
	// 检查手机验证码是否通过
	@url("/auth/verify-sms-code")
	@post
	verifySmsCode() {}
	// 用户注册
	@url("/auth/register")
	@post
	register() {}
	// 查询剩余次数
	@url("/auth/login-retry-chance")
	@post
	getLoginRetryChance() {}
	// 用户登录
	@url("/auth/login")
	@post
	login() {}
	// 密码重置
	@url("/auth/password-reset")
	@post
	resetPassword() {}
	// 登出
	@url("/auth/logout")
	@post
	logout() {}
	// 刷新token
	@url("/auth/refresh")
	@post
	refreshToken() {}
	// 获取用户封面
	@url("/user/cover-image")
	@get
	getCover() {}
	// 获取用户头像
	@url("/user/avatar")
	@get
	getAvatar() {}
}

export default new Login();
