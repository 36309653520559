<template>
  <div id="app">
    <headers></headers>
    <router-view v-wechat-title="`${title}`"></router-view>
    <footers></footers>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      title: "观天者",
    };
  },
  components: {},
  computed: {},
  methods: {},
  mounted() {
    
  },
  updated() {
    // 设置动态标题
    if (this.$route.meta.title) {
      this.title = `${this.$route.meta.title}-观天者`;
    } else {
      this.title = "观天者";
    }
  },
  created() {},
};
</script>
<style lang="less">
@import "less/frontend.less";

// 阿里字体图标设置
.icon,
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100%;
}
</style>
