// 用户

const Home = () => import("../views/home/home.vue");
const userAgreement = () => import("../views/agreement/userAgreement.vue");
const notFound = () => import("../views/404.vue");

export default [
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "home",
		component: Home,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/agreement",
		name: "agreement",
		component: userAgreement,
		meta: {
			title: "用户协议",
			keepAlive: false,
		},
	},
	{
		path: "*",
		name: "notFound",
		component: notFound,
		meta: {
			title: "404",
			keepAlive: false,
		},
	},
];
