// 个人信息
import axios from "axios";
const getDefaultState = () => {
	return {
		access_token: '',
		refresh_token: '',
		miniInfo: {},
	};
};
const state = getDefaultState();
const mutations = {
	getToken(state, res) {
		state.access_token = res["access_token"];
		state.refresh_token = res["refresh_token"];
	},
	getNavInfo(state, res) {
		state.miniInfo = res;
	},
	resetModuleState(state, res) {
		Object.assign(state, getDefaultState());
	},
};

const actions = {
	resetState({ commit }) {
		commit("resetModuleState");
	},
	refreshToken({ commit, state }) {
		let params = {
			refresh_token: Cookies.get("refresh_token"),
		};
		Api.Login.refreshToken(params)
			.then((resData) => {
				commit("getToken", {
					access_token: resData.data.access_token,
					refresh_token: Cookies.get("refresh_token"),
				});
				Cookies.set("access_token", resData.data.access_token);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	requestMiniInfo({ commit }) {
		Api.User.getMiniInfo()
			.then((resData) => {
				// console.log("获取header用户信息成功", resData);
				if (resData.status == 200) {
					commit("getNavInfo", resData.data);
				}
			})
			.catch((error) => {
				console.log("获取header用户信息失败", error);
			});
	},
};

export default {
	state,
	mutations,
	actions,
};
