var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "欢迎登录观天者",
        visible: _vm.visibleLogin,
        "append-to-body": true,
        "show-close": true,
        width: "450px",
        "destroy-on-close": true,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visibleLogin = $event
        },
        opened: _vm.openDialog,
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        { ref: "loginForm", attrs: { model: _vm.loginForm, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入邮箱/手机号" },
                model: {
                  value: _vm.loginForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "phone", $$v)
                  },
                  expression: "loginForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password", error: _vm.pwdError } },
            [
              _c("el-input", {
                attrs: {
                  "show-password": "",
                  placeholder: "密码8-16位，须包含大小写字母及数字",
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "other flex-align-between" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.changeRemember },
              model: {
                value: _vm.remember,
                callback: function ($$v) {
                  _vm.remember = $$v
                },
                expression: "remember",
              },
            },
            [_vm._v("记住密码")]
          ),
          _c(
            "span",
            {
              staticClass: "forget csp",
              on: {
                click: function ($event) {
                  _vm.visibleForget = true
                },
              },
            },
            [_vm._v("忘记密码")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isLoginLoading },
              on: { click: _vm.login },
            },
            [_vm._v(_vm._s(_vm.isLoginLoading ? "登录中" : "登录"))]
          ),
          _c("p", { staticClass: "to-rigister" }, [
            _vm._v("没有账号？去"),
            _c(
              "span",
              { staticClass: "rigister csp", on: { click: _vm.toRegister } },
              [_vm._v("注册")]
            ),
          ]),
        ],
        1
      ),
      _c("forget", {
        attrs: { visibleForget: _vm.visibleForget },
        on: {
          close: function ($event) {
            _vm.visibleForget = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }