import { get, post, url } from "../http";

class Cameras {
	// 查询机位列表
	@url("/cameras")
	@get
	getCamerasList() {}
	// 获取机位详情
	@url("/camera/info")
	@get
	getCamerasInfo() {}
	// 修改机位名称
	@url("/camera/name")
	@post
	updateCameraName() {}
	// 根据时间区间查(时长)询时间间隔
	@url("/camera/time-interval")
	@get
	getCameraInterval() {}
	// 机位图片信息
	@url("/camera/viewports")
	@get
	getCameraViewports() {}
	// 留言列表
	@url("/camera/message/list")
	@get
	getMessageList() {}
	// 留言精选操作
	@url("/camera/message/set-show")
	@post
	setShowMessage() {}
	// 查询机位留言方式
	@url("/camera/message/mode")
	@get
	getMessageMode() {}
	// 设置机位留言方式
	@url("/camera/message/mode")
	@post
	setMessageMode() {}
	// 对图片进行天气识别
	@url("/camera/weather-classify")
	@post
	weatherRecognition() {}
	// 查询生成视频需要消耗的积分
	@url("/camera/video/consumption")
	@get
	requestConsumption() {}
	// 生成视频
	@url("/camera/video/make")
	@post
	videoMake() {}

}

export default new Cameras();
