import Home from './Home'
import Login from './login'
import User from './User'
import Cameras from './cameras'


const Api = {
  Home,
  Login,
  User,
  Cameras
}

// 导出
export default Api
