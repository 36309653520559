// import { get, post, url} from '../service/ApiDecorator'
import { get, post, url } from "../http";

class User {
	// 获取header用户信息
	@url("/user/mini-info")
	@get
	getMiniInfo() {}
	// 查询用户信息
	@url("/user/info")
	@get
	userInfo() {}
	// 查询邮箱认证状态
	@url("/user/email/verify-state")
	@get
	getEmailStatus() {}
	// 发送邮箱认证邮件
	@url("/user/email/send")
	@post
	sendEmail() {}
	// 修改邮箱
	@url("/user/email")
	@post
	changeEmail(data) {}
	// 查询邮箱
	@url("/user/email")
	@get
	getEmail(data) {}
	// 修改手机号
	@url("/user/phone-num")
	@post
	changePhone(data) {}
	// 查询手机号
	@url("/user/phone-num")
	@get
	getPhone(data) {}
	// 发送手机验证码
	@url("/user/send-sms-code")
	@post
	sendSmsCode() {}
	// 验证手机验证码
	@url("/user/verify-sms-code")
	@post
	verifySmsCode() {}
	// 查询名字可修改次数
	@url("/user/name/remaining-attempt")
	@get
	getNameChangeTimes() {}
	// 修改名字
	@url("/user/name")
	@post
	changeName(data) {}
	// 查询名字
	@url("/user/name")
	@get
	getName(data) {}
	// 修改个性签名
	@url("/user/signature")
	@post
	changeSignature(data) {}
	// 查询个性签名
	@url("/user/signature")
	@get
	getSignature(data) {}
	// 修改性别
	@url("/user/gender")
	@post
	changeGender(data) {}
	// 查询性别
	@url("/user/gender")
	@get
	getGender(data) {}
	// 修改所在城市
	@url("/user/city")
	@post
	changeCity(data) {}
	// 查询所在城市
	@url("/user/city")
	@get
	getCity(data) {}
	// 修改所在行业
	@url("/user/industry")
	@post
	changeIndustry(data) {}
	// 查询所在行业
	@url("/user/industry")
	@get
	getIndustry(data) {}
	// 修改个人简介
	@url("/user/introduction")
	@post
	changeIntroduction(data) {}
	// 查询个人简介
	@url("/user/introduction")
	@get
	getIntroduction(data) {}
	// 查询用户当前IP
	@url("/user/ip")
	@get
	getIp() {}


}

export default new User();
