"use strict";
import headers from "./headers.vue";
import footers from "./footer.vue";
import pagination from "./pagination.vue";

const Coms = {
  headers,
  footers,
  pagination,
};

// vue批量引入全局组件
const PageComs = {
  install(Vue) {
    for (let key in Coms) {
      Vue.component(key, Coms[key]);
    }
  },
};

export default PageComs;
